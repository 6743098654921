import React, { PureComponent } from "react";
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faCameraRotate, faCheck, faRepeat, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import LocalPersistency from "../services/LocalPersistency.js";

/**
 * Prompter for photo capture; this opens a dialog for photo capture and then invokes the callback upon completion
 */
class PhotoPrompter extends PureComponent {

    constructor(props) {
        super(props);
        this.disabled = props.disabled;
        this.imageCallback = props.imageCallback;
        this.cameraPreference = LocalPersistency.getPhotoCameraPreference();
        if (!this.cameraPreference)
            this.cameraPreference = 0;
        else {
            this.cameraPreference = parseInt(this.cameraPreference);
        }
        this.rendering = false;
        this.cameraList = [];

        this.state = {
            camChangeEnabled: true,
            isOpen: false,
            imgUrl: null,
            photoTakeState: 'camera',
            imageDataURL: null,
            orientation: 0,
        };
        this.stream = null;
        this.lastOrientationUpdate = 0;
        this.videoRef = React.createRef();

        this.handleChangeCamera = this.handleChangeCamera.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handleTakePhoto = this.handleTakePhoto.bind(this);
        this.handleConfirmPhoto = this.handleConfirmPhoto.bind(this);
        this.handleRepeat = this.handleRepeat.bind(this);
        this.stopTracks = this.stopTracks.bind(this);
        this.orientationListener = this.orientationListener.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
    }

    // obtain rotation matrix from input angles
    getRotationMatrix(alpha, beta, gamma) {
        const degtorad = Math.PI / 180;
        var cX = Math.cos(beta  * degtorad);
        var cY = Math.cos(gamma * degtorad);
        var cZ = Math.cos(alpha * degtorad);
        var sX = Math.sin(beta  * degtorad);
        var sY = Math.sin(gamma * degtorad);
        var sZ = Math.sin(alpha * degtorad);
    
        var m11 = cZ * cY - sZ * sX * sY;
        var m12 = - cX * sZ;
        var m13 = cY * sZ * sX + cZ * sY;
    
        var m21 = cY * sZ + cZ * sX * sY;
        var m22 = cZ * cX;
        var m23 = sZ * sY - cZ * cY * sX;
    
        var m31 = - cX * sY;
        var m32 = sX;
        var m33 = cX * cY;

        return [
            m13, m11, m12,
            m23, m21, m22,
            m33, m31, m32
        ];
    }

    // retrieve orientation angle from given matrix
    getOrientationAngle(matrix) {
        var sy = Math.sqrt(matrix[0] * matrix[0] + matrix[3] * matrix[3]);
     
        var singular = sy < 1e-6;

        var x = 0;
     
        if (!singular) {
            x = Math.atan2(matrix[7], matrix[8]);
        } else {
            x = Math.atan2(-matrix[5], matrix[4]);
        }

        return x * 180 / Math.PI;
    }

    // transforms a device orientation from the source event to a one of the following: [0, 90, 180, 270]
    getDeviceOrientation(e) {
        if (!e || !e.alpha || !e.beta || !e.gamma) {
            return 0;
        }

        const altAngle = this.getOrientationAngle(this.getRotationMatrix(e.alpha, e.beta, e.gamma));

        // 50 to 130 --> 270 (left-oriented landscape)
        if (altAngle > 50 && altAngle < 130) {
            return 270;
        }

        // -130 to -50 --> 90 (right-oriented landscape)
        if (altAngle > -130 && altAngle < -50) {
            return 90;
        }

        // -50 to 50 --> 0 (portrait)
        if (altAngle >= -50 && altAngle <= 50) {
            return 0;
        }

        // otherwise (-130 to -180 and 180 to 130) --> 180 (upside-down portrait)
        return 180;
    }

    // hooked orientation listener
    orientationListener(e) {

        // update orientation once in a 500ms
        const ts = Date.now();
        if (ts - this.lastOrientationUpdate < 500) {
            return;
        }
        this.lastOrientationUpdate = ts;

        const orientation = this.getDeviceOrientation(e);
        if (orientation !== this.state.orientation) {
            this.setState({
                orientation: orientation
            });
        }
    }

    // open the dialog and hook listeners
    openDialog() {
        this.setState({
            isOpen: true
        });
        this.handleRepeat();

        window.addEventListener('deviceorientationabsolute', this.orientationListener);
    }

    // close the dialog and unhook listeners
    closeDialog() {
        this.stopTracks();
        this.setState({
            isOpen: false
        });

        window.removeEventListener('deviceorientationabsolute', this.orientationListener);
    }

    // React hook before unmnount - stop video tracks in order to not block further getMediaDevices calls
    componentWillUnmount() {
        this.stopTracks();
        window.removeEventListener('deviceorientationabsolute', this.orientationListener);
    }

    // retrieve a list of all available video inputs
    async getListOfVideoInputs() {
        const videoInputDevices = await navigator.mediaDevices.enumerateDevices();
        // filter all videoinput devices
        const preFiltered = videoInputDevices.filter((device) => device.kind === "videoinput");

        var camList = [];

        // prefer video devices facing back (to the environment)
        for (var i in preFiltered) {
            if (preFiltered[i].label.includes("back") || preFiltered[i].label.includes("environment") || preFiltered[i].label.includes("rear")) {
                camList.push(preFiltered[i]);
            }
        }

        // if no such device is found, fall back to all video devices
        if (camList.length === 0) {
            camList = preFiltered;
        }

        return camList;
    }

    async handleChangeCamera() {

        // disable camera change for a few moments
        this.setState({
            camChangeEnabled: false
        });

        // after some time, enable the change again
        var oldThis = this;
        setTimeout(function() {
            oldThis.setState({
                camChangeEnabled: true
            });
        }, 1000);

        // move camera preference
        this.cameraPreference = this.cameraPreference + 1;
        if (this.cameraList.length > 0) {
            this.cameraPreference = (this.cameraPreference % this.cameraList.length);
        }
        else {
            this.cameraPreference = 0;
        }

        this.rendering = false;

        LocalPersistency.setPhotoCameraPreference(this.cameraPreference);

        // force the component to update
        this.forceUpdate();
    }

    // take photo and export imageDataURL
    handleTakePhoto() {
        var canvas = document.createElement("canvas");

        var invertTranslateBack = false;

        // "landscape"
        if (this.state.orientation === 90 || this.state.orientation === 270) {
            canvas.width = this.videoRef.current.videoHeight;
            canvas.height = this.videoRef.current.videoWidth;
            invertTranslateBack = true;
        }
        // "portrait"
        else {
            canvas.width = this.videoRef.current.videoWidth;
            canvas.height = this.videoRef.current.videoHeight;
        }

        var context = canvas.getContext("2d");

        const translateW = canvas.width;
        const translateH = canvas.height;

        context.save();
        context.translate(translateW/2, translateH/2);
        context.rotate(this.state.orientation*Math.PI/180.0);

        // landscape-shot photos must switch the returning coordinates in order to match the corners
        if (invertTranslateBack) {
            context.translate(-translateH/2, -translateW/2);
        }
        else {
            context.translate(-translateW/2, -translateH/2);
        }

        context.drawImage(this.videoRef.current, 0, 0, this.videoRef.current.videoWidth, this.videoRef.current.videoHeight);
        context.restore();

        this.stopTracks();

        var dataURL = canvas.toDataURL("image/jpeg");

        this.setState({
            photoTakeState: 'confirm',
            imageDataURL: dataURL
        });
    }

    // photo confirmed - call the callback and reset the component state
    handleConfirmPhoto() {
        this.imageCallback(this.state.imageDataURL);
        this.handleRepeat();
    }

    // repeat the camera shooting attempt
    handleRepeat() {
        this.rendering = false;
        this.setState({
            photoTakeState: 'camera',
            imageDataURL: null
        });
        this.forceUpdate();
    }

    // stop all tracks within the internal video element - this is crucial for all further getUserMedia calls
    stopTracks() {
        // stop all video tracks (release the stream)
        if (this.stream) {
            this.stream.getVideoTracks().forEach(t => t.stop());
            this.stream = null;
        }

        // unhook the source (release the device itself)
        if (this.videoRef.current) {
            try {
                this.videoRef.current.srcObject = null;
            }
            catch (err) {
                this.videoRef.current.src = '';
            }
        }
    }

    render() {

        // refresh the video element only if requested or if opening the dialog
        if (!this.rendering && this.state.isOpen) {
            this.rendering = true;

            this.stopTracks();

            this.getListOfVideoInputs().then((videoInputs) => {

                this.cameraList = videoInputs;

                var oldThis = this;

                // guard
                if (this.cameraList.length > 0) {
                    this.cameraPreference = (this.cameraPreference % this.cameraList.length);
                }
                else {
                    this.cameraPreference = 0;
                }

                navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            // we request an ideal resolution of 3840x2160, but the browser may select closest available
                            // note that we are using portrait-mode aspect ratio, because our default view falls back to portrait mode
                            width: { min: 1920, ideal: 3840 },
                            height: { min: 1080, ideal: 2160 },
                            aspectRatio: 0.5625, // inverse of 1.777777778 (16:9)
                            deviceId: {
                                exact: videoInputs[this.cameraPreference].deviceId,
                            },
                        },
                        audio: false // no audio required
                    })
                    .then((stream) => {
                        oldThis.stream = stream;

                        // re-hook the source for the video element
                        if (oldThis.videoRef.current) {
                            oldThis.videoRef.current.pause();
                            oldThis.videoRef.current.srcObject = stream;
                            oldThis.videoRef.current.play();
                        } else {
                            oldThis.rendering = false;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        console.error(error.constraint);
                    }
                );
            });
        }

        // determine the GUI
        var displayElement = (<></>);
        var controlElement = (<></>);

        // are we capturing the photo?
        if (this.state.photoTakeState === 'camera') {

            // display the video element
            displayElement = (
                <span className="video-capture-container">
                    <video className="photo-video" id="video" ref={this.videoRef} autoPlay muted playsInline></video>
                    <button className="camera-selecter" onClick={this.handleChangeCamera} disabled={!this.state.camChangeEnabled}><FontAwesomeIcon size="1x" icon={faCameraRotate} /></button>
                    <span className="camera-info">{this.cameraPreference}</span>
                </span>
            );

            // rotate the "take a photo" button with the device
            var orientationClass = "text-blue or-"+this.state.orientation+"-deg";

            // allow the user to take a photo
            controlElement = (
                <span className="centered">
                    <IconButton disabled={this.disabled} onClick={this.handleTakePhoto} className={orientationClass}>
                        <FontAwesomeIcon size="2x" icon={faCamera} />
                    </IconButton>
                </span>
            );
        }
        // are we confirming the taken photo?
        else if (this.state.photoTakeState === 'confirm') {

            // display the preview based on the input data URL
            displayElement = (
                <img className="photo-video" alt={"Náhled"} src={this.state.imageDataURL} />
            );

            // allow for decision - "confirm" or "try again"
            controlElement = (
                <span className="centered">
                    <IconButton disabled={this.disabled} onClick={this.handleConfirmPhoto} className="padded-right text-green">
                        <FontAwesomeIcon size="lg" icon={faCheck} />
                    </IconButton>
                    <IconButton disabled={this.disabled} onClick={this.handleRepeat} className="text-red">
                        <FontAwesomeIcon size="lg" icon={faRepeat} />
                    </IconButton>
                </span>
            );
        }

        return (
            <>
                <IconButton disabled={this.disabled} onClick={this.openDialog}>
                    <FontAwesomeIcon size="1x" icon={faCamera} />
                </IconButton>
                <Dialog open={this.state.isOpen} fullWidth={true}>
                    <DialogTitle className="right-align">
                        <IconButton disabled={this.disabled} onClick={this.closeDialog} className="text-red-brighter">
                            <FontAwesomeIcon size="1x" icon={faCircleXmark} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {displayElement}
                            {controlElement}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        );

    }
};

export default PhotoPrompter;
