
/**
 * A class maintaining local persistency for several preferences and runtime data
 */
const LocalPersistency = {

    Position_Evaporator: "evaporator",
    Position_Electrician: "electrician",
    Position_Operator: "operator",

    getCameraPreference() {
        return localStorage.getItem("cameraPreference");
    },

    setCameraPreference(preference) {
        localStorage.setItem("cameraPreference", preference);
    },

    getPhotoCameraPreference() {
        return localStorage.getItem("cameraPreferencePhoto");
    },

    setPhotoCameraPreference(preference) {
        localStorage.setItem("cameraPreferencePhoto", preference);
    },

    getUserIdentity() {
        const data = sessionStorage.getItem('user-identity');
        if (data === null || typeof data === 'undefined') {
            return null;
        }

        return JSON.parse(data);
    },

    setUserIdentity(data) {
        if (data === null) {
            sessionStorage.removeItem('user-identity');
            return;
        }
        sessionStorage.setItem('user-identity', JSON.stringify(data));
    },

    resetUserIdentity() {
        sessionStorage.removeItem('user-identity');
    },

    getStoredPosition() {
        var storedPosition = localStorage.getItem('user-position');
        if (storedPosition === null || typeof storedPosition === 'undefined') {
            storedPosition = LocalPersistency.Position_Operator;
        }

        return storedPosition;
    },

    setStoredPosition(position) {
        localStorage.setItem('user-position', position);
    },

    setDeviceId(id) {
        if (id === null) {
            sessionStorage.removeItem('device-id');
            return;
        }
        sessionStorage.setItem('device-id', id);
    },

    getDeviceId() {
        return sessionStorage.getItem('device-id');
    },

    setEvaporatorId(id) {
        if (id === null) {
            sessionStorage.removeItem('evaporator-id');
            return;
        }
        sessionStorage.setItem('evaporator-id', id);
    },

    getEvaporatorId() {
        return sessionStorage.getItem('evaporator-id');
    },

    setValveId(id) {
        if (id === null) {
            sessionStorage.removeItem('valve-id');
            return;
        }
        sessionStorage.setItem('valve-id', id);
    },

    getValveId() {
        return sessionStorage.getItem('valve-id');
    },

};

export default LocalPersistency;