import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';

function LogDialog({ isOpen, onClose }) {

    const logState = useSelector(state => state.uploadQueue);
    const logMessages = logState.stateMessages;

    var noteText = (<></>);
    if (logMessages.length === 0) {
        noteText = (<>Žádné zprávy v záznamu</>);
    }
    
    return (
        <Dialog open={isOpen}>
            <DialogTitle id="alert-dialog-title">
                {"Záznam"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {logMessages?.map(msg => <span className="smallText" key={msg[0]}>
                        {msg[1]}<br />
                    </span>)}
                    {noteText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Zavřít</Button>
            </DialogActions>
        </Dialog>
    );
}

export default LogDialog;


