/**
 * Wrapper for page footer
 */
function PageFooter({ children }) {
    return (
        <div className="app-footer">
            {children}
        </div>
    );
}

export default PageFooter;
