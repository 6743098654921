import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentState, removeFromQueue, forceQueue, setUploadProgress, pushStateMessage, moveHeadToTail } from './UploadQueue.js';
import { db } from './Database.js';
import Uploader from '../modules/Uploader.js';
import FileAccessor from '../services/FileAccessor.js';

/**
 * Upload service running "in the background"
 */
const UploadService = () => {
    const dispatch = useDispatch();
    const uploadQueue = useSelector((state) => state.uploadQueue.queue);
    
    // select all uploads
    db.uploads.toArray().then((recs) => {
        if (uploadQueue.length > 0) {
            return;
        }
        if (recs.length > 0) {
            dispatch(forceQueue(recs));
        }
    }, [dispatch, uploadQueue]);

    const [cachedFiles, setCachedFiles] = React.useState();
    const [cachedFilesId, setCachedFilesId] = React.useState();
    const [uploadState, setUploadState] = React.useState(false);

    const uploadRound = React.useCallback(async () => {
        if (uploadState !== false) {
            return;
        }

        try {

            // no longer needed, however, I left it here commented just in case
            //dispatch(pushStateMessage("Upload tick (N = "+uploadQueue.length+")"));

            setUploadState(true);

            if (uploadQueue.length > 0) {
                    
                const currentItem = uploadQueue[0];
                try {
                    dispatch(setCurrentState('UPLOADING'));
                        
                    var allowRemove = false;
                    var allowPostpone = false;
                        
                    const uploadRecord = await db.uploads.get(currentItem.id);
                    if (typeof(uploadRecord) === 'undefined') {
                        allowRemove = true;
                    }
                    else {

                        var formDataPromise = new Promise(async (resolve) => {
                            if (cachedFilesId !== currentItem.id) {

                                dispatch(pushStateMessage("Cache miss, loading"));

                                const uploadFiles = await db.uploadFileNames.where('uploadId').equals(currentItem.id);

                                const uploadFilesCount = await uploadFiles.count();
                                if (uploadFilesCount <= 0) {
                                    resolve([ false, null ]);
                                }

                                var fa = new FileAccessor();

                                var fileInfos = [];
                                var promises = [];

                                uploadFiles.each((fl, cur) => {
                                    fileInfos.push(fl);
                                    dispatch(pushStateMessage("Resolved path: " + fl.filePath));
                                    promises.push(fa.readFile("photos", fl.filePath));
                                }).then(() => {

                                    var formDataProto = new FormData();
                                    
                                    formDataProto.append('deviceId', uploadRecord.deviceId);
                                    if (typeof uploadRecord.evaporatorId !== 'undefined' && uploadRecord.evaporatorId !== null) {
                                        formDataProto.append('evaporatorId', uploadRecord.evaporatorId);
                                    }
                                    if (typeof uploadRecord.valveId !== 'undefined' && uploadRecord.valveId !== null) {
                                        formDataProto.append('valveId', uploadRecord.valveId);
                                    }
                                    formDataProto.append('userId', uploadRecord.userId);
                                    formDataProto.append('captureInfo', uploadRecord.captureInfo);

                                    dispatch(pushStateMessage("Waiting for promise group"));

                                    Promise.all(promises).then((dumps) => {
                                        dispatch(pushStateMessage("All promises finished"));

                                        var someSuccess = false;
                                        for (var i in dumps) {
                                            if (dumps[i][0] !== true) {
                                                dispatch(pushStateMessage("Error on load: " + dumps[i][1]));
                                            }
                                            else {
                                                const blob = new Blob([dumps[i][1]], { type: "image/jpeg" });
                                                const file = new File([blob], currentItem.id + "-" + fileInfos[i].id + ".jpg", { type: "image/jpeg" });
                                                        
                                                formDataProto.append("images[]", file);
                                                someSuccess = true;
                                            }
                                        }

                                        if (someSuccess) {
                                            setCachedFiles(formDataProto);
                                            setCachedFilesId(currentItem.id);
                                        }

                                        resolve([ someSuccess, formDataProto ]);
                                    }).catch((err) => {
                                        dispatch(pushStateMessage("Error when waiting: "+err));
                                    });

                                }).catch((err) => {
                                    dispatch(pushStateMessage("Error when loading: " + err));
                                });
                            }
                            else {
                                resolve([ true, cachedFiles ]);
                            }
                        });

                        var formDataResult = await formDataPromise;

                        if (formDataResult[0] !== true) {
                            dispatch(pushStateMessage("Upload removed - invalid record"));
                            allowRemove = true;
                            allowPostpone = false;
                        }
                        else {
                            dispatch(pushStateMessage("Uploading..."));
                            const uploader = new Uploader();
                            const response = await uploader.uploadBackground(formDataResult[1], (progress) => {
                                dispatch(setUploadProgress(progress));
                            });

                            if (response.status === 200) {
                                dispatch(pushStateMessage("Upload OK"));
                                allowRemove = true;
                            }
                            else {
                                allowPostpone = true;
                                dispatch(pushStateMessage("Upload status code = "+response.status+ ", body = "+JSON.stringify(response.data)));
                            }
                        }
                    }

                    if (allowRemove) {

                        dispatch(pushStateMessage("Cleanup id " + currentItem.id));

                        const uploadFiles = await db.uploadFileNames.where('uploadId').equals(currentItem.id);

                        var fa = new FileAccessor();

                        var promises = [];

                        await uploadFiles.each((fl, cur) => {
                            promises.push(fa.removeFile("photos", fl.filePath));
                        });

                        await Promise.all(promises);

                        await db.uploadFileNames.where('uploadId').equals(currentItem.id).delete();
                        await db.uploads.where('id').equals(currentItem.id).delete();
                        dispatch(removeFromQueue(currentItem));
                    }
                    else if (allowPostpone) {
                        dispatch(moveHeadToTail());
                    }
                } catch (error) {
                    console.error('Error uploading: ', error);
                    dispatch(pushStateMessage("Upload error: " + error));
                }

                dispatch(setCurrentState('NONE'));
                dispatch(setUploadProgress(0));
            }
        }
        catch (error) {
            console.error('Error initiating upload: ', error);
            dispatch(pushStateMessage("Upload init error: " + error));
            dispatch(setCurrentState('NONE'));
            dispatch(setUploadProgress(0));
        }

        setUploadState(false);

    }, [uploadQueue, dispatch, cachedFiles, cachedFilesId, uploadState]);

    React.useEffect(() => {
        const timer = setInterval(uploadRound, 3000);
        return () => clearInterval(timer);
    }, [uploadRound]);

    return (<></>);
};

export default UploadService;
