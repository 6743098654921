import { createSlice } from '@reduxjs/toolkit'

/**
 * Upload queue service
 */
export const UploadQueueSlice = createSlice({
    name: 'UploadQueue',
    initialState: {
        currentState: 'NONE',
        preparation: false,
        progress: 0,
        queue: [],
        stateMessages: [],
        stateMessageCounter: 1
    },
    reducers: {
        setCurrentState: (state, action) => {
            state.currentState = action.payload;
        },
        addToQueue: (state, action) => {
            // avoid duplicates
            for (var i in state.queue) {
                if (typeof state.queue[i].id !== 'undefined' && state.queue[i].id === action.payload.id) {
                    return;
                }
            }
            state.queue.push(action.payload);
        },
        removeFromQueue: (state, action) => {
            state.queue = state.queue.filter((item) => {
                return (item.id !== action.payload.id);
            });
        },
        moveHeadToTail: (state, action) => {
            var head = state.queue.shift();
            state.queue.push(head);
        },
        forceQueue: (state, action) => {
            state.queue = action.payload;
        },
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
        setPreparationState: (state, action) => {
            state.preparation = action.payload;
        },
        pushStateMessage: (state, action) => {

            const currentdate = new Date();

            const stamped = currentdate.toLocaleTimeString() + ": " + action.payload;

            state.stateMessageCounter++;

            state.stateMessages.push([ state.stateMessageCounter, stamped ]);
            if (state.stateMessages.length > 10) {
                state.stateMessages.shift();
            }
        }
    }
});

export const { setCurrentState, addToQueue, removeFromQueue, forceQueue, setUploadProgress, setPreparationState, pushStateMessage, moveHeadToTail } = UploadQueueSlice.actions;

export default UploadQueueSlice.reducer
