import Dexie from 'dexie';

export const db = new Dexie('UploadsDB');

db.version(1).stores({
    uploads: '++id, userId, deviceId, captureInfo, photoCount, dateTimeStart',
    uploadFiles: '++id, uploadId, fileDump'
});

db.version(2).stores({
    uploadFiles: null,
    uploadFileNames: '++id, uploadId, filePath'
});

// 8. 11. 2024 - extend database - uploads should now contain evaporatorId and valveId
db.version(3).stores({
    uploads: '++id, userId, deviceId, evaporatorId, valveId, captureInfo, photoCount, dateTimeStart',
    uploadFileNames: '++id, uploadId, filePath'
});
