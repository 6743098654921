
/**
 * File accessor class to access the internal virtual filesystem for large file storage
 */
class FileAccessor {

    FS(cb) {
        const RequestSize = 60*1024*1024*1024; // request 60 GB, the browser may give us less

        navigator.persistentStorage = navigator.persistentStorage || navigator.webkitPersistentStorage;

        navigator.persistentStorage.requestQuota(RequestSize, function(grantedBytes) {
            window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

            window.requestFileSystem(window.PERSISTENT, RequestSize,
                function(fs) { cb(fs); },
                function(err) { console.err(err); }
            );
        });
    }

    // write given blob to a file
    writeFile(inputBlob, dstDir, dstFile) {
        return new Promise((resolve) => {
            this.FS(function(fs) {
                fs.root.getDirectory(dstDir, { create: true }, (dir) => {
                    dir.getFile(dstFile,
                        { create: true },
                        (fileEntry) => {
                            fileEntry.createWriter(function (fileWriter) {
                                fileWriter.write(inputBlob);
                                resolve(true);
                            });
                        }
                    );
                });
            });
        });
    }

    // read a file as array buffer
    readFile(srcDir, srcFile) {
        return new Promise((resolve) => {
            this.FS(async (fs) => {
                fs.root.getDirectory(srcDir, { create: true }, (dirEntry) => {
                    dirEntry.getFile(
                        srcFile,
                        { create: false },
                        function (fileEntry) {
                            fileEntry.file((fl) => {
                                let reader = new FileReader();
        
                                reader.readAsArrayBuffer(fl);
        
                                reader.onload = function() {
                                    resolve([ true, reader.result ]);
                                };

                                reader.onerror = (err) => {
                                    resolve([ false, srcFile+ " - " +err ]);
                                };

                                reader.onabort = () => {
                                    resolve([ false, null ]);
                                };
                            }, (err) => {
                                resolve([ false, srcFile+ " - " +err ]);
                            });
                        },
                        function (err) {
                            resolve([ false, srcFile+ " - " +err ]);
                        }
                    );
                });
                
            });
        });
    }

    // removes given file from directory
    removeFile(srcDir, fileToDelete) {
        return new Promise((resolve) => {
            this.FS(async (fs) => {

                fs.root.getDirectory(srcDir, { create: true }, (dirEntry) => {
                    dirEntry.getFile(
                        fileToDelete,
                        { create: false },
                        (fileEntry) => {
                            fileEntry.remove(() => {
                                resolve(true);
                            });
                        },
                        (err) => {
                            resolve(false);
                        }
                    );
                });
                
            });
        });
    }

    // lists all files within a directory
    listAll(dir, filesOnly) {
        return new Promise((resolve, reject) => {

            this.FS(async (fs) => {

                const iterate = (dirEntry) => {
                    try {
                        var reader = dirEntry.createReader();
                        const ignoreDirs = (typeof filesOnly !== 'undefined' && filesOnly === true);

                        reader.readEntries(function(entries) {

                            var arr = [];

                            for (var i = 0; i < entries.length; i++) {
                                if (!entries.isDirectory || !ignoreDirs) {
                                    arr.push(entries[i]);
                                }
                            }

                            resolve(arr);
                        });
                    }
                    catch (ex) {
                        reject(ex);
                    }
                };

                if (dir === "" || dir === "/" || typeof dir === 'undefined') {
                    iterate(fs.root);
                }
                else {
                    fs.root.getDirectory(dir, { create: true }, iterate, (err) => {
                        reject(err);
                    });
                }
            });
        });
    }

};

export default FileAccessor;