import './app.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from './pages/Login.js';
import ReadDevicePage from './pages/ReadDevice.js';
import ReadEvaporatorPage from './pages/ReadEvaporator.js';
import ReadValvePage from './pages/ReadValve.js';
import PhotoNewPage from './pages/PhotoNew.js';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/read-device-id" element={<ReadDevicePage />} />
                <Route path="/read-evaporator-id" element={<ReadEvaporatorPage />} />
                <Route path="/read-valve-id" element={<ReadValvePage />} />
                <Route path="/photo-new" element={<PhotoNewPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
