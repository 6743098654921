import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Scanned code component - displays either the scanned code, or dummy text
 */
function ScannedCode({ code, iconRef }) {
    if (code !== null && code.length > 0) {
        return (
            <div className="full-width">
                <div className="login-credentials">
                    <div className="cred-container">
                        <div className="cred-left">
                            <FontAwesomeIcon size="1x" icon={iconRef} />
                        </div>
                        <div className="cred-right">
                            Kód: <strong>{code}</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="full-width">
                <div className="login-credentials">
                    Žádné údaje zatím nebyly načteny<br />
                </div>
            </div>
        );
    }
}

export default ScannedCode;
