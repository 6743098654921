import axios from 'axios';
import AppConfig from '../config.json';
import EventBus from '../services/EventBus.js';
import LocalPersistency from '../services/LocalPersistency.js';

/**
 * Uploader module
 */
class Uploader {
    constructor() {
        this.uploadBackground = this.uploadBackground.bind(this);
        this.upload = this.upload.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
        
        this.cancelSource = null;
    }
    
    // Upload method called from background "thread"
    uploadBackground(formData, progressCallback) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelSource = source;
        
        const axiosConfig = {
            onUploadProgress: progressEvent => {
                if (typeof progressCallback !== 'undefined') {
                    progressCallback(Math.ceil(progressEvent.progress*100));
                }
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: source.token,
            // never throw - we will handle everything ourselves
            validateStatus: () => true
        };
        
        var targetUrl = (window.location.hostname.includes("app.carrier-qa.internal"))
                            ? new URL(AppConfig.backend.baseUrl + AppConfig.backend.uploadEndpoint)
                            : new URL(AppConfig.backendDev.baseUrl + AppConfig.backendDev.uploadEndpoint);
        
        return axios.post(targetUrl.toString(), formData, axiosConfig);
    }
    
    // synchronous upload method
    upload(filesArray) {
        
        const storedData = sessionStorage.getItem('user-identity');
        const userData = JSON.parse(storedData);
        const storedDeviceId = LocalPersistency.getDeviceId();
        const storedEvaporatorId = LocalPersistency.getEvaporatorId();
        const storedValveId = LocalPersistency.getValveId();

        const formData = new FormData();
        
        for (var i in filesArray) {
            formData.append('images[]', filesArray[i].fileName);
        }
        formData.append('deviceId', storedDeviceId);
        formData.append('evaporatorId', storedEvaporatorId);
        formData.append('valveId', storedValveId);
        formData.append('userId', userData.name + " " + userData.surname + ", " + userData.id);
        formData.append('captureInfo', window.navigator.userAgent);
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.cancelSource = source;
        
        const axiosConfig = {
            onUploadProgress: progressEvent => {
                EventBus.dispatch("uploadSingleProgress", { progress: Math.ceil(progressEvent.progress*100) });
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: source.token
        };
        
        var targetUrl = (window.location.hostname.includes("app.carrier-qa.internal"))
                            ? new URL(AppConfig.backend.baseUrl + AppConfig.backend.uploadEndpoint)
                            : new URL(AppConfig.backendDev.baseUrl + AppConfig.backendDev.uploadEndpoint);
        
        return axios.post(targetUrl.toString(), formData, axiosConfig);
    }
    
    cancelUpload() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }
    }
}

export default Uploader;
