import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * Confirm dialog used for confirming the upload of outgoing photos
 */
function InProgressDialog() {
    return (
        <Dialog open={true}>
            <DialogTitle id="alert-dialog-title">
                {"Probíhá příprava..."}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Vyčkejte malý moment, probíhá příprava fotografií pro odeslání...
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default InProgressDialog;
