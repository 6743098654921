import { useNavigate } from 'react-router-dom';
import { useBackListener } from './useBackListener.js';

/**
 * Router extension to wrap components with router access
 */
export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();
        const backListener = useBackListener(() => {
        });
    
        return (
            <Component
                navigate={navigate}
                backListener={backListener}
                {...props}
            />
        );
    };

    return Wrapper;
};
